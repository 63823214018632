// @ts-nocheck

// This is for MSF Release - comment this out if running locally
// Start
const endpoints = {
    serviceBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    mintApiBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    revenueserviceBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    demoApiBaseUrl: ' https://mint.microsoftfederal.com/?demoMode=true',
    bonusApiUrl: 'https://bonus.ppe.mint.azure.us/'
  };
  
  export const environment =  {
    spoofUser: 'LUWHITE',
    production: false,
    environment: 'ppe',
    serviceBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    mintapiBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    mintapiDetailBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    revenueserviceBaseUrl: 'https://mint-core.ppe.mint.azure.us/',
    demoapiBaseUrl: ' https://mint.microsoftfederal.com/?demoMode=true',
    demoAppUrl: 'https://mint.microsoftfederal.com/?demoMode=true',
    bonusApiUrl: 'https://bonus.ppe.mint.azure.us/',
    payoutApiUrl: 'https://payout.ppe.mint.azure.us/',
    utpResourceId: 'b2406a33-9114-4fa4-b366-ff1935cc625f',
    appInsightsKey: '888cf0c5-f36b-f93a-b485-061d4f5b322a',
    appInsightsEndpoint: 'applicationinsights.usv2/track',
    aadResource: 'api://be589a6a-98b8-41e1-bc68-4a1a869a657b',
    aadClientId: '0f314954-6adb-4906-a2dc-2aede5368799',
    aadTenant: '86b1dded-d548-4e1b-9584-900494145773',
    aadInstance: 'https://login.microsoftonline.us/',
    isDemoMode2: false,
    enableISA: true,
    enablePWA: false,
    ocvAppId: 2461,
    isOcvEnabled: false,
    isUserVoiceEnabled: false ,
    isBotEnabled: false,
    isServiceNowEnabled: false,
    adobeOriginUri: 'https://secure.na3.adobesign.com',
    // PageTour Config
    isPageTourEnabled: false,
    pageTourConfig: {
        resourceId: 'cbd1fcd7-3769-4123-8ac9-cb51188ec3ba' , // TODO:msfed changes required.
        apiUrl: 'https://pagetourserviceuat.trafficmanager.net/api/v1/', // TODO:msfed changes required
        tenantId: '7c42b380-d7d7-4c67-95f0-a43b8ede2f3b'
    },
    // Configuration for authentication
    authConfig: {
        tenant: '86b1dded-d548-4e1b-9584-900494145773',
        instance: 'https://login.microsoftonline.us/',
        clientId: '0f314954-6adb-4906-a2dc-2aede5368799',
        extraQueryParameter: 'nux=1',
        popUp: false,
        navigateToLoginRequestUrl: true,
        cacheLocation: 'sessionStorage',
        endpoints: {
            // This unnecessary colon replacement of endpoint names is in place to avoid minification collapsing the endpoints object.
            // The minification was replacing this with "https://mint-core.ppe.mint.azure.us/: ..." which breaks when the url is injected.
            [endpoints.serviceBaseUrl.replace(':', ':')]: 'api://be589a6a-98b8-41e1-bc68-4a1a869a657b',
            [endpoints.mintApiBaseUrl.replace(':', ':')]: 'api://be589a6a-98b8-41e1-bc68-4a1a869a657b',
            [endpoints.revenueserviceBaseUrl.replace(':', ':')]: 'api://be589a6a-98b8-41e1-bc68-4a1a869a657b',
            [endpoints.demoApiBaseUrl.replace(':', ':')]: 'api://be589a6a-98b8-41e1-bc68-4a1a869a657b',
            [endpoints.bonusApiUrl.replace(':', ':')]: 'api://be589a6a-98b8-41e1-bc68-4a1a869a657b'
        }
    },
    corpUrlMessage: '<em>Please access using your Corporate machine and microsoft.com credentials</em>',
    showMintInsider: false,
    graphResource: 'https://graph.microsoft.us',
    graphDomain: 'microsoftfederal.com',
    supportHref: 'mailto: svc-fedicsup@microsoftfederal.com',
    redirectHomeUrl: 'https://aka.ms/mintfederal',
    showAccount360: false,
    useUserVoice: false,
    useTelemetryCxtForUserAlias: false,
    appBaseUrl: 'mint-ppe.microsoftfederal.com',
    showVideoInPopup: false,
    complianceRedirectCorpUrl: true,
    corpBaseUrl: 'https://mint.microsoft.com',
    showBonusPayoutInfo:true || false,
    isHardTransferFeatureEnabled:true || false,
};
// Stop

// The following is for running the project locally. Uncomment these lines after commenting the top.

// Start
// const clientId = 'c3906525-e854-4cc3-a6b1-45a303a40fb6';
// const aadResource = 'api://ffcca1d8-8147-4d74-9a9e-c3f0dda4a6ef'; // TODO:msfed changes required.
// const aadInstance = 'https://login.microsoftonline.us/';
// const aadTenant = '19c9c736-1bd7-4a86-ab79-ca07aee53677';

// const endpoints = {
//   serviceBaseUrl: 'https://earningsapiprod.azurewebsites.net/', // TODO:msfed changes will update later
//   baseUrl: 'https://msfmintapi.dev.microsoftfederal.com/', // TODO:msfed changes update once demo environment is created
// };

// export const environment : IEnvironment = {
//     spoofUser: '',
//     production: false,
//     uat: false,
//     environment: 'UAT',
//     serviceBaseUrl: endpoints.serviceBaseUrl,
//     mintapiBaseUrl: endpoints.baseUrl,
//     mintapiDetailBaseUrl: endpoints.baseUrl,
//     revenueserviceBaseUrl: endpoints.baseUrl,
//     demoapiBaseUrl: endpoints.baseUrl,
//     demoAppUrl: endpoints.baseUrl, // TODO:msfed changes update once demo environment is created
//     utpResourceId: 'b2406a33-9114-4fa4-b366-ff1935cc625f',
//     appInsightsKey: '820adcb9-03ac-f943-98bc-9bfc87caea42', // TODO:msfed changes required. should be configurable
//     appInsightsEndpoint: 'https://dc.applicationinsights.us/v2/track',
//     aadResource: aadResource,
//     aadClientId: clientId,
//     aadInstance: aadInstance,
//     aadTenant: aadTenant,
//     isDemoMode2: false,
//     enableISA: true,
//     enablePWA: false,
//     ocvEnvironment: 0,
//     ocvAppId: 2461,
//     isOcvEnabled: false,
//     isUserVoiceEnabled: false,
//     isBotEnabled: false,
//     isServiceNowEnabled: false,
//     adobeOriginUri: 'https://secure.na3.adobesign.com',
//     // PageTour Config
//     isPageTourEnabled: false,
//     pageTourConfig: {
//         resourceId: 'cbd1fcd7-3769-4123-8ac9-cb51188ec3ba', // TODO:msfed changes required.
//         apiUrl: 'https://pagetourserviceuat.trafficmanager.net/api/v1/', // TODO:msfed changes required
//         tenantId: '7c42b380-d7d7-4c67-95f0-a43b8ede2f3b'
//     },
//     // Configuration for authentication
//     authConfig: {
//         tenant: aadTenant,
//         instance: aadInstance,
//         clientId: clientId,
//         extraQueryParameter: 'nux=1',
//         popUp: false,
//         navigateToLoginRequestUrl: true,
//         cacheLocation: 'sessionStorage',
//         endpoints: { // TODO:msfed changes required
//             'https://mintapi-uat.incentivecompensation.microsoft.com/': 'http://ParticipantExperienceServiceTest',
//             'https://mintapi-dev.microsoft.com/': 'http://ParticipantExperienceServiceTest',
//             'https://mintservicedtl-test.azurewebsites.net/': 'http://ParticipantExperienceServiceTest',
//             'https://mintdemo2.icmintprod.p.azurewebsites.net/': 'http://ParticipantExperienceServiceTest'
//         }
//     },
//     corpUrlMessage: '<em>Please access using your Corporate machine and microsoft.com credentials</em>',
//     showMintInsider: false,
//     graphResource: 'https://graph.microsoft.us',
//     graphDomain: 'microsoftfederal.com',
//     supportHref: 'mailto: svc-fedicsup@microsoftfederal.com',
//     redirectHomeUrl: 'https://aka.ms/mintfederal',
//     showAccount360: false,
//     useUserVoice: false,
//     useTelemetryCxtForUserAlias: false,
//     appBaseUrl: 'mint.microsoftfederal.com',
//     showVideoInPopup: false,
//     complianceRedirectCorpUrl: true,
//     corpBaseUrl: 'https://mint.microsoft.com'
// };
// Stop
