import { IEnvironment } from './iEnvironment';

const clientId = '490d8e2d-4831-41fd-bf0f-991e5f9eb9ae';
const aadResource = 'http://ParticipantExperienceService';
const aadInstance = 'https://login.microsoftonline.com/';
const aadTenant = 'microsoft.onmicrosoft.com';

const endpoints = {
  serviceBaseUrl: 'https://mintapi.incentivecompensation.microsoft.com/',
  mintApiBaseUrl: 'https://fd-selexp-mintcorecutover-prod.azurefd.net/',
  mintApiDetailBaseUrl: 'https://mintapidetail.microsoft.com/',
  revenueserviceBaseUrl: 'https://mintdetailapi.incentivecompensation.microsoft.com/',
  demoApiBaseUrl: 'https://mintdemo2.icmintprod.p.azurewebsites.net/',
  bonusApiUrl: 'https://bonusapiuat.azurewebsites.net/',
  payoutApiUrl: 'https://payoutapiuat.azurewebsites.net/'
};

export const environmentDemo: IEnvironment = {
  spoofUser: '',

  production: false,
  uat: false,
  environment: 'Demo',
  serviceBaseUrl: endpoints.serviceBaseUrl,
  mintapiBaseUrl: endpoints.mintApiBaseUrl,
  mintapiDetailBaseUrl: endpoints.mintApiDetailBaseUrl,
  revenueserviceBaseUrl: endpoints.revenueserviceBaseUrl,
  demoapiBaseUrl: endpoints.demoApiBaseUrl,
  demoAppUrl: '',
  bonusApiUrl: endpoints.bonusApiUrl,
  payoutApiUrl: endpoints.payoutApiUrl,
  appInsightsKey: '57772889-e936-4ff6-a17f-d4c2d852ce96',
  appInsightsEndpoint: 'https://dc.services.visualstudio.com/v2/track',
  utpResourceId: 'b2406a33-9114-4fa4-b366-ff1935cc625f',
  aadResource: aadResource,
  aadClientId: clientId,
  aadInstance: aadInstance,
  aadTenant: aadTenant,
  isDemoMode2: true,
  enableISA: true,
  ocvEnvironment: 1,
  isOcvEnabled: true,
  isUserVoiceEnabled: true,
  isPageTourEnabled: true,
  isBotEnabled: true,
  isServiceNowEnabled: true,
  enablePWA: false,
  ocvAppId: 2461,
  adobeOriginUri: 'https://secure.na3.adobesign.com',
  // PageTour Config
  pageTourConfig: {
    resourceId: 'cbd1fcd7-3769-4123-8ac9-cb51188ec3ba',
    apiUrl: 'https://pagetourserviceuatwest.azurewebsites.net/api/v1/',
    tenantId: '7c42b380-d7d7-4c67-95f0-a43b8ede2f3b'
  },
  // Configuration for authentication
  authConfig: {
    tenant: aadTenant,
    clientId: clientId,
    instance: aadInstance,
    extraQueryParameter: 'nux=1',
    popUp: false,
    navigateToLoginRequestUrl: true,
    cacheLocation: 'sessionStorage',
    endpoints: {
      [endpoints.serviceBaseUrl]: aadResource,
      [endpoints.mintApiBaseUrl]: aadResource,
      [endpoints.mintApiDetailBaseUrl]: aadResource,
      [endpoints.revenueserviceBaseUrl]: aadResource,
      [endpoints.demoApiBaseUrl]: aadResource
    }
  },
  corpUrlMessage: '',
  showMintInsider: true,
  graphResource: 'https://graph.microsoft.com',
  graphDomain: 'microsoft.com',
  supportHref: 'https://aka.ms/wwichelp',
  redirectHomeUrl: 'https://aka.ms/mint',
  showAccount360: true,
  useUserVoice: true,
  useTelemetryCxtForUserAlias: true,
  appBaseUrl: 'mint.microsoft.com',
  showVideoInPopup: true,
  complianceRedirectCorpUrl: false,
  corpBaseUrl: 'https://mint.microsoft.com',
  showBonusPayoutInfo: true,
  isHardTransferFeatureEnabled: true
};
