import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { AuthenticationService } from '@mint-libs/authentication';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const enum EventName {
  PageLoad = 'PageLoad',
  DropdownSelected = 'DropdownSelected',
  DropdownChange = 'DropdownChange',
  PageNavigation = 'PageNavigation',
  CheckboxChange = 'CheckboxChange',
  ListChange = 'ListChange',
  ButtonClick = 'ButtonClick',
  TabChange = 'TabChange',
  TabNavigation = 'TabNavigation',
  PanelOpened = 'PanelOpened',
  PanelClosed = 'PanelClosed',
  PickerSelected = 'PickerSelected',
  PickerDeselected = 'PickerDeselected',
  TileAdded = 'TileAdded',
  TileClicked = 'TileClicked',
  TileDeleted = 'TileDeleted',
  TileLoaded = 'TileLoaded',
  OnFocus = 'OnFocus',
  OnBlur = 'OnBlur',
  TextChange = 'TextChange',
  Scroll = 'Scroll',
  SectionRendering = 'SectionRendering',
  BackEndAPICall = 'BackEndAPICall',
  Rendering = 'Rendering',
  NA = 'NA',
  Hover = 'Hover',
  LinkClicked = 'LinkClicked'
}

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {
  private utpAIExtension: ApplicationInsights;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(ENVIRONMENT_CONFIG) private envConfig: any, private authService: AuthenticationService, private msalBroadcastService: MsalBroadcastService) {
    this.init();
  }

  init() {
    this.utpAIExtension = new ApplicationInsights({ config: { instrumentationKey: this.envConfig.appInsightsKey, enableAutoRouteTracking: true, autoTrackPageVisitTime: true } });
    this.utpAIExtension.loadAppInsights();
    this.utpAIExtension.trackPageView();
    this.setUserContext(this.utpAIExtension);
  }

  setUserContext(ai: ApplicationInsights) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        const allAccounts = this.authService.msalService.instance.getAllAccounts();
        if (allAccounts && allAccounts.length > 0) {
          const userAccount = allAccounts.find(account => account.username.endsWith(`@${this.envConfig.graphDomain}`));
          ai.setAuthenticatedUserContext(userAccount.username, userAccount.username, true);
        }
      });
  }

  trackException(error: Error, severity: string = '5', props: any = null) {
    this.utpAIExtension.trackException({ error, severityLevel: parseInt(severity) }, props);
  }

  trackPageview(featureName, properties?) {
    properties.FeatureName = featureName;
    this.utpAIExtension.trackPageView({ name: featureName, properties: properties });
    this.trackUtpFeatureEvent(featureName, 'PageView', EventName.PageLoad, properties);
  }

  trackEvent(featureName, actionName, properties, isLogUtp?: boolean) {
    properties.FeatureName = featureName;
    if (isLogUtp) {
      this.trackUtpFeatureEvent(actionName, actionName, EventName.ButtonClick, properties);
    } else {
      this.utpAIExtension.trackEvent({ name: actionName }, properties);
    }
  }

  trackClickEvent(featureName, actionName, eventName, properties) {
    properties.FeatureName = featureName;
    this.trackUtpFeatureEvent(featureName, actionName, eventName, properties);
  }

  trackUtpFeatureEvent(featureName, actionName, eventName, properties) {
    var utpFeatureUsageEvent = { featureName, actionName, eventName, SubFeatureName: properties?.SubFeatureName, ...properties };
    this.utpAIExtension.trackEvent({ name: actionName }, utpFeatureUsageEvent);
  }

  startTrackPage(featureName, actionName, properties) {
    var utpFeatureUsageEvent = { actionName: `START_${actionName}`, featureName, ...properties };
    this.utpAIExtension.trackEvent({ name: utpFeatureUsageEvent.actionName }, utpFeatureUsageEvent);
  }

  stopTrackPage(featureName, actionName, properties) {
    var utpFeatureUsageEvent = { actionName: `END_${actionName}`, featureName, ...properties };
    this.utpAIExtension.trackEvent({ name: utpFeatureUsageEvent.actionName }, utpFeatureUsageEvent);
  }
}
